import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
//import Todo from './Todo';
import Todo from '../../Todo';
//import { db } from './firebase';
import { db } from '../../firebase';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
//import { useAuth } from '../../contexts/authContext'
//import { useAuth } from '../../contexts/authContext'
import { useAuth } from '../../contexts/authContext';

const style = {
    bg: `h-screen w-screen p-4 bg-gradient-to-r from-[#2F80ED] to-[#1CB5E0]`,
    container: `bg-slate-100 max-w-[500px] w-full m-auto rounded-md shadow-xl p-4`,
    heading: `text-3xl font-bold text-center text-gray-800 p-2`,
    form: `flex justify-between`,
    input: `border p-2 w-full text-xl`,
    button: `border p-4 ml-2 bg-purple-500 text-slate-100`,
    count: `text-center p-2`,
  };

const Home = () => {
    const { currentUser } = useAuth()
    const [todos, setTodos] = useState([]);
    const [input, setInput] = useState('');

    // Create todo
    const createTodo = async (e) => {
        e.preventDefault(e);
        if (input === '') {
        alert('Please enter a valid todo');
        return;
        }
        await addDoc(collection(db, currentUser.uid), {
        text: input,
        completed: false,
        });
        setInput('');
    };

      // Read todo from firebase
    useEffect(() => {
        console.log(currentUser);
        const q = query(collection(db, currentUser.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let todosArr = [];

        querySnapshot.forEach((doc) => {
            console.log(doc);
            todosArr.push({ ...doc.data(), id: doc.id });
        });
        setTodos(todosArr);
        });
        return () => unsubscribe();
    }, []);

    // Update todo in firebase
    const toggleComplete = async (todo) => {
        await updateDoc(doc(db, currentUser.uid, todo.id), {
        completed: !todo.completed,
        });
    };

    // Delete todo
    const deleteTodo = async (id) => {
        await deleteDoc(doc(db, currentUser.uid, id));
    };

    return (
        <>
        <div className='text-2xl font-bold pt-14'>Hello {currentUser.displayName ? currentUser.displayName : currentUser.email}, you are now logged in.</div>
        <div className={style.bg}>
        <div className={style.container}>
            <h3 className={style.heading}>Todo</h3>
            <form onSubmit={createTodo} className={style.form}>
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={style.input}
                type='text'
                placeholder='Add Todo'
            />
            <button className={style.button}>
                <AiOutlinePlus size={30} />
            </button>
            </form>
            <ul>
            {todos.map((todo, index) => (
                <Todo
                key={index}
                todo={todo}
                toggleComplete={toggleComplete}
                deleteTodo={deleteTodo}
                />
            ))}
            </ul>
            {todos.length < 1 ? null : (
            <p className={style.count}>{`You have ${todos.length} todos`}</p>
            )}
        </div>
        </div>
        </>
    )
}

export default Home